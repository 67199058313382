import React, {useState} from "react";
import InputComponent from "./input";

function PhoneField(props){

    return <>
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">

            <InputComponent
                id={props.id}
                type={'tel'}
                name={'phone#' + props.id}
                value={props.value}
                onChange={props.onChange}
                placeholder={'Телефоний номер'}
                class={'form-control w-50 rounded-0 border-0 border-bottom'}
            />

            <div className="form-check">
                <input className="form-check-input" type="checkbox" value={props.telegram} id="messengerOptionTelegram" onChange={
                    (e) => props.messengerOptionChange(props.id, e, 'isTelegram')
                } />
                <label className="form-check-label" htmlFor="messengerOptionChange" defaultChecked={props.telegram}>
                    Telegram {props.telegram}
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value={props.whatsapp} id="messengerOptionWhatsapp" onChange={
                    (e) => props.messengerOptionChange(props.id, e, 'isWhatsApp')
                } />
                <label className="form-check-label" htmlFor="messengerOptionChange" defaultChecked={props.whatsapp}>
                    WhatsApp {props.whatsapp}
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value={props.viber} id="messengerOptionViber" onChange={
                    (e) => props.messengerOptionChange(props.id, e, 'isViber')
                }/>
                <label className="form-check-label" htmlFor="messengerOptionChange" defaultChecked={props.viber}>
                    Viber {props.viber}
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value={props.signal} id="messengerOptionViber" onChange={
                    (e) => props.messengerOptionChange(props.id, e, 'isSignal')
                }/>
                <label className="form-check-label" htmlFor="messengerOptionChange" defaultChecked={props.signal}>
                    Signal {props.signal}
                </label>
            </div>

            {props.id === 0 && <button onClick={props.add} className={'btn btn-secondary rounded-0'}>+</button>}
            {props.id !== 0 && <button onClick={props.remove} className={'btn btn-outline-danger rounded-0'}>-</button>}
        </div>
    </>
}

export default PhoneField;
