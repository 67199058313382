import logo from './logo.svg';
import './App.css';
import * as docx from "docx";
import { saveAs } from 'file-saver';
import React, {useState} from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import {
    AlignmentType,
    BorderStyle, convertInchesToTwip,
    Document, HeadingLevel,
    ImageRun,
    Packer,
    Paragraph,
    StyleLevel,
    Table,
    TableCell,
    TableOfContents,
    TableRow, VerticalAlign, WidthType
} from "docx";

function Profile(props) {

    // Photo upload

    const [file, setFile] = useState();

    function handleChangeFile(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }


    // FIELD's

    const [field0, setField0] = useState();
    const [field1, setField1] = useState();
    const [field2, setField2] = useState();
    const [field3, setField3] = useState();
    const [field4, setField4] = useState();
    const [field5, setField5] = useState();
    const [field6, setField6] = useState();
    const [field7, setField7] = useState();
    const [field8, setField8] = useState();
    const [field9, setField9] = useState();

    const _img = "/img.jpeg";

    const _title = field0?.split(',')[0] ?? `profile_${new Date()}`;
    // https://docx.js.org/#/usage/tables

    const _rowTable = (index, field, placeholder = '') => {
        return  new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 10,
                        type: WidthType.PERCENTAGE
                    },
                    children: [new Paragraph(index)],
                }),
                new TableCell({
                    width: {
                        size: 90,
                        type: WidthType.PERCENTAGE
                    },
                    children: [new Paragraph(  `${placeholder + ': ' + field ?? ''}`)],
                }),
            ],
        });
    }

    const generate = async ()  =>{

        const blobImage = await fetch(file ?? _img).then(r => r.blob());

        const table3 = new Table({
            // columnWidths: [4505, 4505],
            columnWidths: [350, 7550],
            width: {
                size: 100,
                type: WidthType.AUTO,
            },
            rows: [
                // new TableRow({
                //     children: [
                //         new TableCell({
                //             width: {
                //                 size: 5505,
                //                 type: WidthType.DXA,
                //             },
                //             children: [new Paragraph("Hello")],
                //         }),
                //         new TableCell({
                //             children: [],
                //         }),
                //     ],
                // }),
                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE
                            },
                            children: [new Paragraph('')],
                            verticalAlign: VerticalAlign.TOP,
                        }),
                        new TableCell({
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size:90,
                                type: WidthType.PERCENTAGE
                            },
                            // margins: {
                            //     top: convertInchesToTwip(0.69),
                            //     bottom: convertInchesToTwip(0.69),
                            //     left: convertInchesToTwip(0.69),
                            //     right: convertInchesToTwip(0.69),
                            // },
                            children: [
                                new docx.Paragraph({
                                    children: [
                                        new ImageRun({
                                            data: blobImage,
                                            transformation: {
                                                width: 100,
                                                height: 120,
                                            },
                                        }),
                                        new docx.TextRun({
                                            text: field0 ?? '',
                                            bold: true,
                                        })
                                    ]
                                }),
                            ],
                        }),
                    ],
                }),
                _rowTable("1", field1, 'Контакти'),
                _rowTable("2", field2, 'Email'),
                _rowTable("3", field3, 'Пошукова система Google/Yandex'),
                _rowTable("4", field4, 'SOFAROOMBOT'),
                _rowTable("5", field5, 'ORC_SEEKER'),
                _rowTable("6", field6, 'reveng.ee'),
                _rowTable("7", field7, 'Додаткова інформація'),
                _rowTable("8", field8, "Родинні та інші зв'язки"),
                _rowTable("9", field9),
            ],
        });


        const doc = new docx.Document({
            sections: [
                {
                    properties: {},
                    children: [
                        // new docx.Paragraph({
                        //     children: [
                        //         new ImageRun({
                        //             data: blobImage,
                        //             transformation: {
                        //                 width: 100,
                        //                 height: 120,
                        //             },
                        //         }),
                        //         new docx.TextRun({
                        //             text: field0 ?? '',
                        //             bold: true
                        //         })
                        //     ]
                        // }),
                        table3,
                        // new docx.Paragraph({
                        //     children: [
                        //         new docx.TextRun("Hello World"),
                        //         new docx.TextRun({
                        //             text: "Foo Bar",
                        //             bold: true
                        //         }),
                        //         new docx.TextRun({
                        //             text: "\tGithub is the best",
                        //             bold: true
                        //         })
                        //     ]
                        // }),
                        // new TableOfContents("Summary", {
                        //     hyperlink: true,
                        //     headingStyleRange: "1-5",
                        //     stylesWithLevels: [new StyleLevel("MySpectacularStyle", 1)],
                        // }),
                        // new Paragraph({
                        //     text: "Header #1",
                        //     heading: HeadingLevel.HEADING_1,
                        //     pageBreakBefore: true,
                        // }),
                        // new Paragraph("I'm a little text very nicely written.'"),
                        // new Paragraph({
                        //     text: "Header #2",
                        //     heading: HeadingLevel.HEADING_1,
                        //     pageBreakBefore: true,
                        // }),
                        // new Paragraph("I'm a other text very nicely written.'"),
                        // new Paragraph({
                        //     text: "Header #2.1",
                        //     heading: HeadingLevel.HEADING_2,
                        // }),
                        // new Paragraph("I'm a another text very nicely written.'"),
                        // new Paragraph({
                        //     text: "My Spectacular Style #1",
                        //     style: "MySpectacularStyle",
                        //     pageBreakBefore: true,
                        // }),
                        // new docx.Paragraph({
                        //     children: [
                        //         new docx.TextRun(field0 ?? ''),
                        //         new docx.TextRun(field1 ?? ''),
                        //         new docx.TextRun(field2 ?? ''),
                        //         new docx.TextRun(field3 ?? ''),
                        //         new docx.TextRun(field4 ?? ''),
                        //         new docx.TextRun(field5 ?? ''),
                        //         new docx.TextRun(field6 ?? ''),
                        //         new docx.TextRun(field7 ?? ''),
                        //         new docx.TextRun(field8 ?? ''),
                        //         new docx.TextRun(field9 ?? ''),
                        //     ]
                        // })
                    ]
                }
            ]
        });

        docx.Packer.toBlob(doc).then((blob) => {
            console.log(blob);
            saveAs(blob, `${_title}.docx`);
            console.log("Document created successfully");
        });
    }

    const _icons = [
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-download"
             viewBox="0 0 16 16">
            <path
                d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>
            <path
                d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share"
             viewBox="0 0 16 16">
            <path
                d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right"
             viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
            <path fill-rule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
        </svg>
    ]

    return (

        <div className="App border border-danger pb-5 border-4 m-5">
            <p className={'bg-warning opacity-75 p-4 text-danger display-6'}>
                Стара версія
            </p>
            <header className="container fixed-top- bg-white p-3">

                <div className="d-flex justify-content-around align-items-center">
                    <h1>Профіль</h1>

                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            Меню
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1" onClick={generate}>{_icons[0]} Word (docx)</Dropdown.Item>
                            {/*<Dropdown.Item href="#">{_icons[0]} PDF</Dropdown.Item>*/}
                            {/*<Dropdown.Item href="#">{_icons[1]} Поділитись</Dropdown.Item>*/}
                            <Dropdown.Item href="#" onClick={props.exitFunc} className={'text-danger'}>{_icons[2]} Вихід</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

            </header>

            <main className={'container mt-5 pt-5'}>
                <div className="form d-flex flex-wrap col-md-8 m-auto">

                    <div className="col-md-3 p-1 wrap-image">
                        <img src={file ?? _img} alt="avatar" className={'btn-img img-fluid rounded w-50'}/>
                        <input type='file' id='file' onChange={handleChangeFile} />
                    </div>
                    <div className="col-md-9 p-1">
                        <textarea name="" id="" cols="30" rows="5" className="form-control mb-2" placeholder={"ПІБ, посада, дата народження"}
                            onChange={(e) => setField0(e.target.value)}
                        >
                            {field0}
                        </textarea>
                    </div>
                    <div className="col-md-1">
                        <small className="text-secondary">
                            1
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"Контакти, соц. мережі"}
                                  onChange={(e) => setField1(e.target.value)}
                        >
                            {field1}
                        </textarea>
                    </div>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            2
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"Email's"}
                                  onChange={(e) => setField2(e.target.value)}
                        >
                            {field2}
                        </textarea>
                    </div>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            3
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={'Google/Yandex'}
                                  onChange={(e) => setField3(e.target.value)}
                        >
                            {field3}
                        </textarea>
                    </div>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            4
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"SOFAROOMBOT"}
                                  onChange={(e) => setField4(e.target.value)}
                        >
                            {field4}
                        </textarea>
                    </div>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            5
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"ORC_SEEKER"}
                                  onChange={(e) => setField5(e.target.value)}
                        >
                            {field5}
                        </textarea>
                    </div>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            6
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"reveng.ee"}
                                  onChange={(e) => setField6(e.target.value)}
                        >
                            {field6}
                        </textarea>
                    </div>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            7
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"Додаткова інформація"}
                                  onChange={(e) => setField7(e.target.value)}
                        >
                            {field7}
                        </textarea>
                    </div>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            8
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"Родинні та інші зв’язки"}
                                  onChange={(e) => setField8(e.target.value)}
                        >
                            {field8}
                        </textarea>
                    </div>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            9
                        </small>
                    </div>
                    <div className="col-md-11">
                        <textarea name="" id="" cols="30" rows="2" className="form-control mb-2"
                                  onChange={(e) => setField9(e.target.value)}
                        >
                            {field9}
                        </textarea>
                    </div>


                    <div className="col-md-12">
                        <button className={'btn btn-primary w-100 p-3 mt-2'} onClick={generate}>
                            {_icons[0]} Завантажить
                        </button>
                    </div>


                </div>
            </main>

            <footer>

            </footer>

        </div>
    );
}

export default Profile;
