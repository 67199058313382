import axios from "axios";
import {BACK_END_URL, API_UPLOAD} from "./config";

const apiService = axios.create({
    baseURL: BACK_END_URL,
});

export const uploadWebApi = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uid', localStorage.getItem('uid'));

        const response = await apiService.post(API_UPLOAD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
};
