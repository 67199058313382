
import './App.css';
import React, {useEffect, useState} from "react";
import PageComponent from "./componennts/page";
import VersionBuild from "./componennts/version_build";
import WebcamComponent from "./componennts/camera";
import {authWebApi} from "./api/auth";
import axios from "axios";

function getDate(res = 'd') {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  const showMonth = month <= 9 ? 0 + '' + month : month;
  const showDate = date <= 9 ? 0 + '' + date : date;

  const code = `${showDate}${showMonth}${year - 2000}`;

  return res === 'd' ? `${showMonth}/${showDate}/${year}` : code;
}

function App() {

  const [canAuth, setCanAuth] = useState(true);
  const [countAuth, setCountAuth] = useState(0);
  const [ip, setIP] = useState("");
  const [qrCode, setQrCode] = useState(null);

  const getDataIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
    localStorage.setItem('ip', ip);
  };

  const _authByQrCode = async(value) => {
    // event.preventDefault();
    try {
      const result = await authWebApi(value);
      setData(result);
      if(result.success){
        localStorage.setItem('uid', result.data.id);
        localStorage.setItem('name', result.data.name);
        setShow(true)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const [data, setData] = useState(null);

// Fetch data when the component mounts
  async function webAuth() {

    if(pass === _pass){
      setShow(true)
    }else{
      try {
        const result = await authWebApi(pass);
        setData(result);
        console.log(result);
        if(result.success){
          localStorage.setItem('uid', result.data.id);
          localStorage.setItem('name', result.data.name);
          setShow(true)
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        let _count = countAuth + 1;
        setCountAuth(_count);
        alert(error.response.data.data)
        if(countAuth >= 3){
          setCanAuth(false)
          localStorage.setItem('BLOCKED_AUTH', true);
        }
      }

      setPass('')
    }
  }

  // code is current data 12.12.2020 - code: 121220
  const _pass = getDate('code');

  const [pass, setPass] = useState('');
  const [show, setShow] = useState(false);

  const checkPass = async () => {

    if(pass === ''){
      alert('Введіть код доступу!')
    }

    await webAuth();
  }

  const exit = () => {
    setShow(false)
    setPass('')
    localStorage.clear();
  }

  useEffect(() => {
    // localStorage.clear();
    getDataIp();

    let uid = localStorage.getItem('uid');
    let isBlockedAuth = localStorage.getItem('BLOCKED_AUTH');

    if(uid){setShow(true)}
    if(isBlockedAuth){setCanAuth(false)}

  }, [show])

  return (

        show ? <PageComponent exitFunc={exit} /> :

            <div className={'container-fluid d-flex justify-content-between align-items-start m-auto'}>
              <div className={'col-md-8 col-12 m-auto'}>
                <div className="App text-center col-md-6 m-auto justify-content-between d-flex flex-column align-items-center min-vh-100">

                  <h1 className={'m-5 display-3'}>Вітаю, друже!</h1>

                  <WebcamComponent funcLogin={_authByQrCode} />

                  {canAuth ? <div className="col-md-8 m-auto p-5 d-flex">
                    <input type="password" minLength={6} maxLength={6} required value={pass}
                           onChange={(e) => setPass(e.target.value)} placeholder={'Код доступу'}
                           className="form-control rounded-0"/>
                    <button className={'btn btn-primary rounded-0'} onClick={checkPass}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                           className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                      </svg>
                    </button>
                  </div> : <div className={'text-danger lead'}>Перевищено кількість спроб авторизації <br/><br/> IP address: <b className="bg-warning-subtle p-2 lead rounded-4">{ip}</b></div>}

                  <div className={'p-5'}>
                    <div className="qr-tooltip top text-dark">
                      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linejoin="round" stroke-width="2"
                              d="M4 4h6v6H4V4Zm10 10h6v6h-6v-6Zm0-10h6v6h-6V4Zm-4 10h.01v.01H10V14Zm0 4h.01v.01H10V18Zm-3 2h.01v.01H7V20Zm0-4h.01v.01H7V16Zm-3 2h.01v.01H4V18Zm0-4h.01v.01H4V14Z"/>
                        <path stroke="currentColor" stroke-linejoin="round" stroke-width="2"
                              d="M7 7h.01v.01H7V7Zm10 10h.01v.01H17V17Z"/>
                      </svg>
                      <text className="p-2">Мобільний застосунок</text>
                      <span className="tooltiptext">
                          <img src=
                                   "/images/qr_app.png"
                               alt="qr_download_app"
                               style={{width: '200px', height:'auto'}}
                          />
                          <small className={'text-white'}>Скануй QR код для завантаження</small>
                      </span>
                    </div>
                    <div className="mt-3">
                      <p>
                        <small className="text-secondary">Powered by <a href="https://strd.pro" className={'text-dark text-decoration-none'} target={'_blank'}>Stride</a></small>
                        <VersionBuild />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className={'col-md-4 text-start d-noned-md-block pt-5'}>*/}
              {/* <div className={'d-flex align-items-center'}>*/}
              {/*   <img src="/images/image_app.avif" className={'img-fluid'} width={200} alt="image_download"/>*/}
              {/*   <h2 className={'text-secondary lead display-3'}>Мобільний застосунок</h2>*/}
              {/* </div>*/}
              {/*  <div className="text-center pt-5">*/}
              {/*    <img src="/images/qr_app.png" alt="qr_app"  className={'img-fluid w-50'} />*/}
              {/*    <br/>*/}
              {/*    <small className={'text-secondary'}>Скануй QR код для завантаження</small>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
  );
}

export default App;
