import React from "react";
import Accordion from 'react-bootstrap/Accordion';

function InfoComponent(){

    return <>
        <div id="information-section" className={'col-md-8 bg-white shadow-sm mb-5 p-4 pt-5 text-start h-100 align-items-center justify-content-center m-auto'}>

            <small className="text-secondary display-1 opacity-25">
                Інформація
            </small>

            <Accordion defaultActiveKey="0" flush>
                {/*MOBILE APP*/}
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Мобільний застосунок</Accordion.Header>
                    <Accordion.Body>
                        <div id={'mobile-app'} className="text-start d-flex flex-column  mt-5">

                            <div className="d-flex justify-content-between align-items-center mb-5">
                                <div className="title-futures">
                                    <small className="text-secondary display-1 opacity-25">
                                        Profile Docx App
                                    </small>
                                </div>
                                <div className="qr-download d-flex flex-column align-items-center">
                                    <img src="/images/qr_app.png" alt="qr_app"  className={'img-fluid'} width={150} />
                                    <small className={'text-secondary'}>Скануй QR код</small>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-5">
                                <div className="title-futures">
                                    <h2>Мобільний застосунок</h2>
                                </div>
                                <div className="symbols d-flex flex-column">
                                    <a href="/download/profile_docx_app_v1.apk" className={'text-decoration-none'} download>
                                        <small className="text-secondary">
                                            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                 xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"
                                                 viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"/>
                                            </svg> Завантажить
                                        </small>
                                    </a>
                                </div>
                            </div>

                            <ul>
                                <li>
                                    ⏳ <b>v.1.1</b>
                                    <p>
                                        Додано історію сканування, відновлення досупу до профайлів через email.
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.1.0</b>
                                    <p>
                                        Перегляд профайлів, пошук, видалення, перегляд по QR коду, поширення профайлів. Авторизація на сайті через QR код та код доступу.
                                    </p>
                                    <a href="/download/profile_docx_app_v1.apk" className={'text-decoration-none'} download>Завантажить v.1.0</a>
                                </li>

                            </ul>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Опції</Accordion.Header>
                    <Accordion.Body>
                        {/*MODE INFORMATION*/}

                        <div id="mode-download" className={'text-start mt-5'}>
                            <h2>Опція <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                           xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"
                                           viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"/>
                            </svg> завантаження</h2>
                            <br/>
                            <p>
                                <b>Викл. опція</b> - Не заповнене поле профайлу буде відображати "{' - Дані відсутні - '.toUpperCase()}" у значенні поля.
                            </p>
                            <p>
                                <b>Вкл. опція</b> - Не заповнене поле профайлу не відображається у docx файлі (Word файлі), але для коректного відкриття: завантажте на Google Drive там відкрийте і завантажте.
                            </p>
                        </div>

                        <div id="mode-clear" className={'text-start mt-5'}>
                            <h2>Опція <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"/>
                            </svg> очищення</h2>
                            <br/>
                            <p>
                                <b>Викл. опція</b> - Після завантаження поля профайлу не очищаються (залишаються заповненими).
                            </p>
                            <p>
                                <b>Вкл. опція</b> - Після завантаження поля профайлу очищаються (залишаються пустими).
                            </p>
                        </div>

                        <div id="mode-save-download" className={'text-start mt-5'}>
                            <h2>Опція <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                           viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"/>
                            </svg> збереження</h2>
                            <br/>
                            <p>
                                <b>Викл. опція</b> - Після завантаження профайл не зберігається на сервері.
                            </p>
                            <p>
                                <b>Вкл. опція</b> - Після завантаження профайл зберігається на сервері, та буде доступний в мобільному застосунку Docx App.
                            </p>
                        </div>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Версії</Accordion.Header>
                    <Accordion.Body>
                        {/*VERSION*/}

                        <div id={'futures'} className="text-start d-flex flex-column  mt-5">

                            <div className="d-flex justify-content-between align-items-end mb-5">
                                <div className="title-futures">
                                    <small className="text-secondary display-1 opacity-25">
                                        Версії
                                    </small>
                                </div>
                                <div className="symbols d-flex flex-column">
                                    <small className="text-secondary">
                                        ✅ - Впроваджені
                                    </small>
                                    <small className="text-secondary">
                                        ⏳ - Майбутні
                                    </small>
                                </div>
                            </div>

                            <h2>Список версій</h2>
                            <br/>
                            <ul>
                                <li>
                                    ⏳ <b>v.2.4</b>
                                    <p>
                                        Новий розділ Автомобілі, розділення тексту для переносу на новий рядок, додавання адрес
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.2.3.3</b>
                                    <p>
                                        Додано завантаження мобільного застосунку <b>Profile Docx App</b>. Оновленно користувацький інтерфейс блоку інформації.
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.2.3.2</b>
                                    <p>
                                        Виправлено відкриття Word файла після завантаження при виключено режимі завантаження.
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.2.3.1</b>
                                    <p>
                                        Розширено список джерел контакту, родиних зв'язків.
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.2.3</b>
                                    <p>
                                        Інтеграція з мобільним застосунок. Додано авторизацію по QR коду.
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.2.2</b>
                                    <p>
                                        Збереження профайлу, автризація за кодом доступу, доступ до профайлу за QR кодом.
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.2.1</b>
                                    <p>
                                        Додано режим завантаження, очищення полів, інформаційний блок. Інтегровано зміст-навігацію.
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.2.0</b>
                                    <p>
                                        Оновлений дизайн, розділення блоків на детальні поля заповнення профайлу.
                                    </p>
                                </li>

                                <li>
                                    ✅ <b>v.1.0</b>
                                    <p>
                                        Генерація профайлу відповідно до шаблону.
                                    </p>
                                </li>

                            </ul>
                        </div>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {/*/!*FUTURES*!/*/}

            {/*<div id={'futures'} className="text-start d-flex flex-column  mt-5">*/}

            {/*    <div className="d-flex justify-content-between align-items-end mb-5">*/}
            {/*        <div className="title-futures">*/}
            {/*            <small className="text-secondary display-1 opacity-25">*/}
            {/*                Майбутні функції*/}
            {/*            </small>*/}
            {/*        </div>*/}
            {/*        <div className="symbols d-flex flex-column">*/}
            {/*            <small className="text-secondary">*/}
            {/*                ✅ - Виконано*/}
            {/*            </small>*/}
            {/*            <small className="text-secondary">*/}
            {/*                ⏳ - В процесі*/}
            {/*            </small>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <h2>Список функцій</h2>*/}
            {/*    <br/>*/}
            {/*    <ul>*/}
            {/*        <li>*/}
            {/*            ✅ Налаштування: Опція очищення після завантаження*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            ✅ QR код: Поширення профайлу через QR код*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            ✅ Збереження профайлу*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            ✅ Авторизація: Вхід через генаративні коди доступу*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            ⏳ Мобільний застосунок на Android: <b>Docx App</b>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            ⏳ Розділ Автомобілі*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            ⏳ Розділ адреси*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}

        </div>
    </>;
}

export default InfoComponent;
