import React, {useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Profile from "../Profile";
import Profile2 from "../v2/Profile";
import VersionBuild from "./version_build";
import InfoComponent from "./info";

function PageComponent(props){

    const _icons = [
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-download"
             viewBox="0 0 16 16">
            <path
                d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>
            <path
                d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share"
             viewBox="0 0 16 16">
            <path
                d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right"
             viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
            <path fill-rule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download"
             viewBox="0 0 16 16">
            <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
            <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy"
             viewBox="0 0 16 16">
            <path d="M11 2H9v3h2z"/>
            <path
                d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear"
             viewBox="0 0 16 16">
            <path
                d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
            <path
                d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person"
             viewBox="0 0 16 16">
            <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
        </svg>
    ]

    const [version, setVersion] = useState(1)

    const _versions = [
        <Profile exitFunc={props.exitFunc} />,
        <Profile2 exitFunc={props.exitFunc} />
    ];

    return <div className={'App container-fluid p-0'}  style={{backgroundColor: '#eeeeee'}}>

        <header className={'fixed-top d-flex justify-content-between align-items-center border shadow p-5 bg-dark text-white pt-2 pb-2'}>
            <div className="title d-flex align-items-center">
                <h2>
                    Profile
                </h2>
                <select className={'m-1 bg-dark text-secondary border-0'} onChange={(e) => setVersion(e.target.value)}>
                    <option value="0" selected={version === 0}>v1.0</option>
                    <option value="1" selected={version === 1}>v2.0</option>
                </select>
            </div>

            <div className="account">
                {
                    localStorage.getItem('name') && <small className={'text-white m-2'}>
                    Вітаю, <b className="text-light">{localStorage.getItem('name')}</b>
                </small>
                }
                {/*<a href="" className={'m-2 text-white text-decoration-none'}>*/}
                {/*    {_icons[4]}  Зберегти*/}
                {/*</a>*/}
                {/*<a href="" className={'m-2 text-white text-decoration-none'}>*/}
                {/*    {_icons[3]}  Завантажть*/}
                {/*</a>*/}

                <div class="btn-group">
                    <button type="button" class="btn btn-outline-light border-0 rounded-1" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="visually-hidden">Toggle Dropdown</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                             className="bi bi-sliders2 text-secondary" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5M12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8m9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5m1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                        </svg>
                    </button>
                    <ul class="dropdown-menu">
                        {/*<li><a class="dropdown-item" href="#">{_icons[6]} Профіль</a></li>*/}
                        {/*<li><a className="dropdown-item" href="#">{_icons[4]} Зберегти</a></li>*/}
                        {/*<li><a className="dropdown-item" href="#">{_icons[3]} Завантажить</a></li>*/}
                        {/*<li><a class="dropdown-item" href="#">{_icons[5]} Налаштування</a></li>*/}



                        <li className={'d-flex flex-column d-block d-md-none'}>
                            <h5 className={'p-2 pb-0'}>Зміст</h5>
                            <a href="#basicInformation" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary border-bottom'}>Основна інформація</a>
                            <a href="#contacts" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Контакти</a>
                            <a href="#emails" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary border-bottom'}>Email's</a>
                            <a href="#link-g" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Google/Yandex</a>
                            <a href="#moreImformation" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary border-bottom'}>Додаткова інформація</a>
                            <a href="#family" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Родині зв'язки</a>
                            <a href="#note" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary border-bottom'}>Нотатка</a>
                            <a href="#photos" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Фотографії</a>
                            {/*<a href="#cars" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Автомобілі</a>*/}
                            {/*<a href="#address" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Адреси</a>*/}
                        </li>


                        <li><a class="dropdown-item d-none d-md-block" href="#information-section">Інфорація</a></li>
                        {/*<li><a class="dropdown-item d-none d-md-block" href="#futures">Майбутні функції</a></li>*/}

                        <li><hr class="dropdown-divider" /></li>
                        <li><a class="dropdown-item text-danger" href="#" onClick={props.exitFunc}>{_icons[2]} Вихід</a></li>
                    </ul>
                </div>
            </div>
        </header>

        <main className={'container pt-5 mt-5 overflow-scroll'} >

            {_versions[version]}

            <InfoComponent />

        </main>

        <footer className={'bg-white p-2 pt-4 pb-4 shadow text-start d-flex justify-content-between'}>
            <small className={'text-secondary p-3'}>
              Copyright ©  2024. <a href="https://lukyanchuk.com" target={'_blank'} className={'text-dark text-decoration-none'}>Y. Lukyanchuk</a>. Made with ❤️ in 🇺🇦
            </small>

            <VersionBuild />
        </footer>
    </div>

}

export default PageComponent;
