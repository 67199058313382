import React from "react";


function VersionBuild() {

    function getDate(res = 'd') {
        const today = new Date();
        const month = today.getMonth() + 1;
        const date = today.getDate();

        const showMonth = month <= 9 ? 0 + '' + month : month;
        const showDate = date <= 9 ? 0 + '' + date : date;

        return res === 'd' ? `${ showDate}${showMonth}` : 'build';
    }

    return <>
        <small className="text-secondary p-3">
            v2.3.3 build: 161124
        </small>
    </>
}

export default VersionBuild;
