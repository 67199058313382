import React, {useRef, useState} from 'react';

import Modal from 'react-bootstrap/Modal';
import QRCode from "react-qr-code";
import { toPng } from 'html-to-image';
import LoadComponent from "./load";

function WindowModal(props){

    const elementRef = useRef(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const downloadQRCode = () => {

        toPng(elementRef.current, { cacheBust: false })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = props.qrCodeText +"_QR_code.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="qr-code">

            <button id={'qrModalShow'} className={'btn btn-outline-dark col-md-2 p-3 mt-2 rounded-0 pt-4 pb-4 text-uppercase shadow-sm'} onClick={handleShow}>
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linejoin="round" stroke-width="2"
                          d="M4 4h6v6H4V4Zm10 10h6v6h-6v-6Zm0-10h6v6h-6V4Zm-4 10h.01v.01H10V14Zm0 4h.01v.01H10V18Zm-3 2h.01v.01H7V20Zm0-4h.01v.01H7V16Zm-3 2h.01v.01H4V18Zm0-4h.01v.01H4V14Z"/>
                    <path stroke="currentColor" stroke-linejoin="round" stroke-width="2"
                          d="M7 7h.01v.01H7V7Zm10 10h.01v.01H17V17Z"/>
                </svg>
                {props.btnText ?? 'SAVE_BUTTON'}
            </button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.text}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        {/*<h1 className={'text-success text-uppercase'}>{props.text}</h1>*/}

                        <br/>

                        {props.qrCodeText ?
                        <div>
                            <div style={{ height: "auto", margin: "0 auto", maxWidth: 512, width: "100%" }}>
                                <QRCode
                                    id="qrCodeProfile"
                                    size={512}
                                    style={{ height: "300px", maxWidth: "100%", width: "100%" }}
                                    value={props.qrCodeText}
                                    viewBox={`0 0 25 25`}
                                    ref={elementRef}
                                />
                            </div>
                            <br/>

                            <button className={'w-100 btn btn-dark p-3 mt-3 rounded-0 pt-4 pb-4 text-uppercase1 shadow-sm'} onClick={downloadQRCode}>
                                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"/>
                                </svg>
                                Завантажить QR код
                            </button>

                            <div className={'p-4 pb-2'}>
                                <a href={'https://office.strd.pro/profile?view=' + props.qrCodeText} target={'_blank'} className={'text-decoration-none text-secondary d-flex align-items-center justify-content-center'}>Переглянути <svg
                                    className="w-6 h-6 text-gray-800 dark:text-white m-1" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                                </svg>
                                </a>
                            </div>

                        </div>: <LoadComponent />
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <small className="text-secondary">
                        QR код для завантаження файлу в мобліьному застосунку Docx App
                    </small>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default WindowModal;
