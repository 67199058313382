import axios from "axios";
import {BACK_END_URL, API_AUTH} from "./config";

// https://office.strd.pro/api/v1/qr/generate?text=text

const apiService = axios.create({
    baseURL: BACK_END_URL,
});

export const authWebApi = async (code) => {
    try {
        const response = await apiService.post(API_AUTH, {code}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
};
