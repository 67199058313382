import React from "react";

function InputComponent(props){

    const _type = props.type ?? 'text'
    const _name = props.name ?? 'name'
    const _placeholder = props.placeholder ?? 'placeholder'

    return <>
        <input type={_type}
               name={_name}
               className={props.class}
               placeholder={_placeholder}
               onChange={props.onChange}
               value={props.value}
        />
    </>

}

export default InputComponent;
