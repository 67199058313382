

import React, {useCallback, useEffect, useRef, useState} from "react";
import Webcam from "react-webcam";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import LoadComponent from "./load";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

const WebcamComponent = (props) => {

    const [havePermissions, setHavePermissions] = useState(false);

    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef, setImgSrc]);

    function checkPermissions() {
            const permissions = navigator.mediaDevices.getUserMedia({audio: false, video: true})
            permissions.then((stream) => {
                setHavePermissions(true)
                capture();
            }).catch((err) => {
                setHavePermissions(false)
                console.log(`${err.name} : ${err.message}`)
            });
    }

    useEffect(()=> {
        checkPermissions()
        setTimeout(capture(), 1000)
    }, []);

    const [data, setData] = React.useState();

    return <div className={'rounded-5 d-flex justify-between-center align-items-center ' + (data && 'position-fixed w-100 h-100 bg-white')}>
        {
            (havePermissions === false && !data) ? <img className={'img-fluid w-50 m-auto'} src="https://img.freepik.com/premium-photo/profile-icon-3d-rendered-icon-user-icon_444773-57.jpg" alt="image"/>
            : <div className={'container text-center'}>
                    {!data && <BarcodeScannerComponent
                        width={500}
                        height={500}
                        onUpdate={(err, result) => {
                            if (result) {
                                setData(result.text)
                                setHavePermissions(false)
                                props.funcLogin(result.text)
                            }
                        }}
                    />}
                    {data && <div className={'display-3 lead text-secondary'}> <LoadComponent /> Авторизація...</div>}
                    {/*<Webcam*/}
                    {/*    audio={false}*/}
                    {/*    height={500}*/}
                    {/*    screenshotFormat="image/jpeg"*/}
                    {/*    width={500}*/}
                    {/*    videoConstraints={videoConstraints}*/}
                    {/*    ref={webcamRef}*/}
                    {/*/>*/}
                </div>
        }
        {imgSrc && <div><img src={imgSrc} alt="img" /> <p className={'text-success lead p-2'}>✅ Фото відправлено</p></div>}
    </div>;
}

export default WebcamComponent;
