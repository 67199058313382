import React, {useState} from "react";

function TextAreaField(props) {
    return <div className={'w-100 d-flex mt-3'}>

        {
            props.typeSize !== 'full' && <div className="col-md-1">
                <small className="text-secondary">
                    {props.index}
                </small>
            </div>
        }

        <div className={props.typeSize === 'full'? "col-md-12 text-start" : "col-md-11 text-start" }>
            <h4 className="text-secondary text-uppercase">
                {props.title}
            </h4>
                        <textarea name={props.name} id={props.id} value={props.value} cols="30" rows="3" className={props.class} placeholder={props.placeholder}
                                  onChange={props.onChange}
                                  style={{
                                      whiteSpace: 'pre-line'
                                  }}
                        >
                            {props.value}
                        </textarea>
        </div>
    </div>
}

export default TextAreaField;
