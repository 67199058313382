import React, {useState} from "react";
import InputComponent from "./input";


function FamilyField(props){

    const _img = "/img.jpeg";

    const member = props.value;

    const _relationshipType = [
        {
            value: "mother",
            label: "Мати"
        },
        {
            value: "father",
            label: "Батько"
        },
        {
            value: "brother",
            label: "Брат"
        },
        {
            value: "sister",
            label: "Сестра"
        },
        {
            value: "wife",
            label: "Дружина"
        },
        {
            value: "husband",
            label: "Чоловік"
        },
        {
            value: "sun",
            label: "Син"
        },
        {
            value: "daughter",
            label: "Донька"
        },
        {
            value: "grandmother",
            label: "Бабушка"
        },
        {
            value: "grandfather",
            label: "Дедушка"
        },
        {
            value: "mother_in_law",
            label: "Теща"
        },
        {
            value: "father_in_law",
            label: "Тесть"
        },
        {
            value: "godmother",
            label: "Хрещена Мати"
        },
        {
            value: "godfather",
            label: "Хрещений Батько"
        },
        {
            value: "nephew",
            label: "Племінник"
        },
        {
            value: "niece",
            label: "Племінниця"
        },
        {
            value: "stepfather",
            label: "Вітчим"
        },
        {
            value: "stepmother",
            label: "Мачуха"
        },
        {
            value: "friend",
            label: "Друг/Подруга"
        },
        {
            value: "familiar",
            label: "Знайомий (а)"
        },
        {
            value: "relative",
            label: "Родич"
        },
        {
            value: "work_connection",
            label: "Робочий зв’язок"
        },
        {
            value: "another_connection",
            label: "Інший зв’язок"
        }
    ];

    return <div className={'p-3'}>
        {/*{member.relationship}*/}
        <div className="d-flex justify-content-between align-items-center">

            <select name="" id="" className={'p-1 border-0 border-bottom m-1 lead'} onChange={(e) => props.change(props.id, e, 'relationship')}>
                <option value="" disabled selected>- родиниий зв’язок -</option>
                {
                    _relationshipType.map((relationship, i) => <option value={relationship.value} selected={relationship.value === member.relationship}>
                        {relationship.label}
                    </option>)
                }
            </select>

            <button onClick={props.remove} className={'btn text-secondary rounded-0 opacity-50'}>x</button>
            {/*{props.id !== 0 && <button onClick={props.remove} className={'btn text-secondary rounded-0 opacity-50'}>x</button>}*/}
        </div>

        <div className="d-flex flex-wrap">

            <div className="col-md-3 col-12 p-2 pt-2">
                <img src={member.photo !== '' ? member.photo : _img} alt="photo" className={'img-fluid rounded-0'}/>
                <input type='file' accept="image/*"  id={'family_photo_' + props.id} onChange={props.handleChangeFilePhotoFamily}  className={'d-none mt-1 form-control rounded-0 border border-dark bg-dark1'} />
                <button onClick={() => {
                    document.getElementById('family_photo_' + props.id)?.click()
                } } className={'btn btn-secondary mt-2 rounded-0 w-100'}>
                    Обрати фотографію
                </button>
            </div>

            <div className="col-md-9 col-12">
                <div className="d-flex flex-wrap justify-content-between align-items-center pb-2">

                    <div className="col-md-6 col-12">
                        <InputComponent
                            type={'text'}
                            name={'family_surname'}
                            value={member.surname}
                            onChange={(e) => props.change(props.id, e, 'surname')}
                            placeholder={'Прізвище'}
                            class={'form-control m-1 rounded-0 border-0 border-bottom'}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <InputComponent
                            type={'text'}
                            name={'family_firstname'}
                            value={member.name}
                            onChange={(e) => props.change(props.id, e, 'name')}
                            placeholder={'Імя'}
                            class={'form-control m-1 rounded-0 border-0 border-bottom border-start'}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <InputComponent
                            type={'text'}
                            name={'family_lastname'}
                            value={member.lastname}
                            onChange={(e) => props.change(props.id, e, 'lastname')}
                            placeholder={'По-батькові'}
                            class={'form-control m-1 rounded-0 border-0 border-bottom'}
                        />
                    </div>

                    <div className="col-md-6 col-12">
                        <InputComponent
                            type={'date'}
                            name={'dataBorn'}
                            value={member.dataBorn}
                            onChange={(e) => props.change(props.id, e, 'dataBorn')}
                            placeholder={'Дата народження'}
                            class={'form-control m-1 rounded-0 border-0 border-bottom border-start'}
                        />
                    </div>

                </div>

                <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">

                    <div className="col-md-6 col-12">
                        <InputComponent
                            type={'tel'}
                            name={'phone'}
                            value={member.phone}
                            onChange={(e) => props.change(props.id, e, 'phone')}
                            placeholder={'Телефоний номер'}
                            class={'form-control m-1 rounded-0 border-0 border-bottom'}
                        />
                    </div>

                    <div className="col-md-6 col-12 d-flex flex-wrap justify-content-around align-items-center p-3 pt-0 pb-0">
                        <div className="form-check col-md-6">
                            <input className="form-check-input" type="checkbox" value={member.isTelegram}
                                   onChange={(e) => props.handleChangeFamilyMessengerValue(props.id, e, 'isTelegram')}
                                   id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Telegram
                            </label>
                        </div>
                        <div className="form-check col-md-6">
                            <input className="form-check-input" type="checkbox" value={member.isWhatsApp} id="flexCheckChecked"
                                   onChange={(e) => props.handleChangeFamilyMessengerValue(props.id, e, 'isWhatsApp')}
                            />
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                WhatsApp
                            </label>
                        </div>
                        <div className="form-check col-md-6">
                            <input className="form-check-input" type="checkbox" value={member.isViber} id="flexCheckChecked"
                                   onChange={(e) => props.handleChangeFamilyMessengerValue(props.id, e, 'isViber')}
                            />
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Viber
                            </label>
                        </div>
                        <div className="form-check col-md-6">
                            <input className="form-check-input" type="checkbox" value={member.isSignal} id="flexCheckChecked"
                                   onChange={(e) => props.handleChangeFamilyMessengerValue(props.id, e, 'isSignal')}
                            />
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Signal
                            </label>
                        </div>
                    </div>

                </div>

                <textarea name="" id="" cols="30" rows="3" value={member.links} className="form-control mb-2 rounded-0 border-1 mt-2 m-1" placeholder={
                    "Посилання на соціальні марежі/Додаткові телефоні номери/URL та ін."
                }
                          onChange={(e) => props.change(props.id, e, 'links')}
                >
                            {member.links}
                </textarea>


            </div>

        </div>

    </div>
}

export default FamilyField;
