
import Loader from "react-js-loader";
import React from "react";

function LoadComponent(props){

    return <>
        <Loader type="spinner-cub"
                // bgColor={''} color={''}
                title={"spinner-cub"} size={100} />
    </>;
}

export default LoadComponent;
