import '../App.css';
import * as docx from "docx";
import {saveAs} from 'file-saver';
import React, {useState} from "react";
import {
    ImageRun,
    Paragraph,
    Table,
    TableCell,
    TableRow, VerticalAlign, WidthType
} from "docx";
import InputComponent from "../componennts/input";
import PhoneField from "../componennts/phone_field";
import FamilyField from "../componennts/family_field";
import TextAreaField from "../componennts/textarea";
import QRCode from "react-qr-code";
import WindowModal from "../componennts/window_model";
import {uploadWebApi} from "../api/upload";
import CarField from "../componennts/car_field";
import AddressField from "../componennts/address_field";

function Profile2(props) {

    const [mode, setMode] = useState(false);
    const [modeClear, setModeClear] = useState(false);
    const [modeSaveDownload, setModeSaveDownload] = useState(false);
    const [modeSave, setModeSave] = useState(false);

    const [fileToUpload, setFileToUpload] = useState(null);

    // Function's
    const getParsedDate = (strDate, symbol, splitSymbol = ' ') => {

        var strSplitDate = String(strDate).split(splitSymbol);
        var date = new Date(strSplitDate[0]);
        // alert(date);
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!

        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = dd + symbol + mm + symbol + yyyy;
        return date.toString();
    }

    // Field form
    const [firstName, setFirstName] = useState("");
    const [surName, setSurName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateBorn, setDateBorn] = useState("");
    const [job, setJob] = useState("");
    const [workPlace, setWorkPlace] = useState("");
    const [names, setNames] = useState("");

    // Osintband
    const [osintband, setOsintband] = useState();
    const [resultHack, setResultHack] = useState();

    // Photo upload

    const [file, setFile] = useState();

    function handleChangeFile(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }


    // FIELD's

    // const [field1, setField1] = useState("");
    const [field2, setField2] = useState("");

    const [field4, setField4] = useState("");
    const [field5, setField5] = useState("");
    const [field6, setField6] = useState("");
    const [field7, setField7] = useState("")

    const [field9, setField9] = useState("");

    // Dynamic field's

    // Contacts dynamic fields

    let [phones, setPhones] = useState([
        {
            text: "",
            isTelegram: false,
            isViber: false,
            isWhatsApp: false,
            isSignal: false
        }
    ]);

    const handleChangePhonesValue = (i, e) => {

        const values = [...phones];
        values[i].text = e.target.value;
        setPhones(values);
    }

    const messengerOptionChange = (i, e, field) => {

        const values = [...phones];

        values[i][field] = e.target.checked;

        setPhones(values);
    }

    const handleInsertPhonesValue = () => {

        setPhones([...phones, {
            text: "",
            isTelegram: false,
            isViber: false,
            isWhatsApp: false,
            isSignal: false
        }]);
    }

    const handleRemovePhonesValue = (i) => {

        setPhones([
            ...phones.slice(0, i),
            ...phones.slice(i + 1)
        ]);
        // const newValues = [...phones];
        // newValues.slice(i, 1);
        // setPhones(newValues);
    }

    // Social media dynamic fields

    const _sourcesSocialMedia = [
        {
            value: "TG",
            label: "Telegram"
        },
        {
            value: "Viber",
            label: "Viber"
        },
        {
            value: "WhA",
            label: "WhatsApp"
        },
        {
            value: "Signal",
            label: "Signal"
        },
        {
            value: "VK",
            label: "ВКонтакте"
        },
        {
            value: "OK",
            label: "Однокласники"
        },
        {
            value: "Mail.ru",
            label: "Mail.ru"
        },
        {
            value: "Inst",
            label: "Instagram"
        },
        {
            value: "FB",
            label: "Facebook"
        },
        {
            value: "X-Twitter",
            label: "X/Twitter"
        },
        {
            value: "LinkedIn",
            label: "LinkedIn"
        },
        {
            value: "TK",
            label: "TikTok"
        },
        {
            value: "Джерело",
            label: "інше"
        }
    ];

    const [socialMedia, setSocialMedia] = useState([
        {
            source: "",
            url_or_id: "",
            username: ""
        }
    ]);

    const handleChangeSocialMediaValue = (i, e, field) => {
        const values = [...socialMedia];
        values[i][field] = e.target.value;
        setSocialMedia(values);
    }

    const handleInsertSocialMediaValue = () => {
        setSocialMedia([...socialMedia, {
            source: "",
            url_or_id: "",
            username: ""
        }]);
    }

    const handleRemoveSocialMediaValue = (i) => {
        setSocialMedia([
            ...socialMedia.slice(0, i),
            ...socialMedia.slice(i + 1)
        ]);
    }

    // Family dynamic field's

    const [family, setFamily] = useState([
        // {
        //     relationship: "",
        //     name: "",
        //     surname: "",
        //     lastname: "",
        //     photo: "",
        //     phone: "",
        //     isTelegram: false,
        //     isViber: false,
        //     isWhatsapp: false,
        //     isSignal: false,
        //     links: "",
        //     dataBorn: ""
        // }
    ])

    const handleChangeFamilyValue = (i, e, field) => {
        const values = [...family];
        values[i][field] = e.target.value;
        setFamily(values);
    }

    const handleChangeFamilyMessengerValue = (i, e, field) => {
        const values = [...family];
        values[i][field] = e.target.checked;
        setFamily(values);
    }

    const handleInsertFamilyValue = () => {
        setFamily([...family, {
            relationship: "",
            name: "",
            surname: "",
            lastname: "",
            photo: "",
            phone: "",
            isTelegram: false,
            isViber: false,
            isWhatsapp: false,
            isSignal: false,
            links: "",
            dataBorn: ""
        }]);
    }

    const handleRemoveFamilyValue = (i) => {
        setFamily([
            ...family.slice(0, i),
            ...family.slice(i + 1)
        ]);
    }


    // google/yandex url's

    const [dataUrls, setDataUrls] = useState([
        // {
        //     url: "",
        //     description: ""
        // }
    ]);

    const setUrlToData = () => {
        setDataUrls([...dataUrls, {
            url: "",
            description: ""
        }])
    }

    const changeUrlInData = (i, e, field) => {
        const values = [...dataUrls];
        values[i][field] = e.target.value;
        setDataUrls(values);
    }

    const removeUrlFromData = (i) => {
        setDataUrls([
            ...dataUrls.slice(0, i),
            ...dataUrls.slice(i + 1)
        ]);
    }

    function handleChangeFilePhotoFamily(i, e) {

        const values = [...family];
        values[i].photo = URL.createObjectURL(e.target.files[0]);
        setFamily(values);
    }

    const [images, setImages] = useState([
        // {
        //   image: "",
        //   width: "",
        //   height: ""
        // },
    ]);

    const addImage = () => {
        setImages([...images, {
            image: "",
            width: "",
            height: ""
        }])
    }

    const changeImage = (i, e) => {
        const values = [...images];
        values[i].image = URL.createObjectURL(e.target.files[0]);
        // values[i].width = _img.width;
        // values[i].height = _img.height;
        setImages(values);
    }

    const removeImage = (i) => {
        setImages([
            ...images.slice(0, i),
            ...images.slice(i + 1)
        ]);
    }

    const _img = "/img.jpeg";

    const _title = surName + `_profile#${new Date().getTime()}`;

    // https://docx.js.org/#/usage/tables

    function createFamilyList(familyData: any[]): any[] {

        const members = [
            new docx.Paragraph({
                children: [
                    new docx.TextRun({
                        text: 'Родинні та інші зв\'язки:',
                        bold: true,
                    }),

                ]
            }),
        ];

        familyData.map(member => members.push(_memberFamilyCard(member)));

        return members;
    }

    function _memberFamilyCard(member: any): Paragraph {

        const blobPhotoMemberFamily = fetch(member.photo ?? _img).then(r => r.blob());

        const _relationshipType = {
            mother: "Мати",
            father: "Батько",
            brother: "Брат",
            sister: "Сестра",
            wife: "Дружина",
            husband: "Чоловік",
            sun: "Син",
            daughter: "Донька",
            grandmother: "Бабушка",
            grandfather: "Дедушка",
            mother_in_law: "Теща",
            father_in_law: "Тесть",
            godmother: "Хрещена Мати",
            godfather: "Хрещений Батько",
            nephew: "Племінник",
            niece: "Племінниця",
            stepfather: "Вітчим",
            stepmother: "Мачуха",
            friend: "Друг/Подруга",
            familiar: "Знайомий (а)",
            relative: "Родич",
            work_connection: "Робочий зв’язок",
            another_connection: "інший зв’язок"
        };

        return new Paragraph({
            children: [
                new docx.TextRun({break: 1}),
                new ImageRun({
                    data: blobPhotoMemberFamily,
                    transformation: {
                        width: 50,
                        height: 50,
                    },
                }),
                new docx.TextRun({
                    text: _relationshipType[member.relationship],
                    italics: true,
                    break: 1,
                }),
                new docx.TextRun({
                    text: member.surname + ' ' + member.name + ' ' + member.lastname,
                    break: 1,
                    bold: true,
                }),
                new docx.TextRun({
                    text: isEmpty(member.dataBorn) ? '' : getParsedDate(member.dataBorn, '.', '-') + ' р.н.',
                    break: 1,
                    bold: true,
                }),
                new docx.TextRun({
                    text: member.phone + _checkMessengerActivityOnPhoneNumber([
                        member.isTelegram,
                        member.isViber,
                        member.isWhatsApp,
                        member.isSignal
                    ]),
                    break: 1,
                }),
                new docx.TextRun({
                    text: member.links,
                    break: 1,
                    // bold: true,
                }),
            ]
        });
    }

    function _checkMessengerActivityOnPhoneNumber(messengers: any[]): String {

        // schema array for messengers
        const _labelMessengers = ['Telegram', 'Viber', 'WhatsApp', 'Signal'];

        let result = '';

        if (messengers.includes(true)) {

            result += ' Активний ';

            messengers.map((m, i) => {
                if (m) {
                    result += _labelMessengers[i] + ' '
                }
            })
        }

        return result;
    }

    function createPhonesList(phonesData: any[]): Paragraph {

        const texts = phonesData.map(phone => new docx.TextRun({
            text: phone.text + _checkMessengerActivityOnPhoneNumber([
                phone.isTelegram,
                phone.isViber,
                phone.isWhatsApp,
                phone.isSignal
            ]),
            break: 1,
        }));

        return new Paragraph({
            children: texts
        });
    }

    function createSocialMediaLinksList(linkData: any[]): Paragraph {

        const texts = linkData.map(link => new docx.TextRun({
            text: link.source + ' ' + link.url_or_id + (isEmpty(link.username) ? '' : ' @' + link.username),
            break: 1,
        }));

        return new Paragraph({
            children: texts
        });
    }

    function createUrlsDataList(linkData: any[]): Paragraph {

        const texts = linkData.map(link => new docx.TextRun({
            text: link.url + (isEmpty(link.description) ? ' #' : ' - ' + link.description),
            break: 1,
        }));

        return new Paragraph({
            children: texts
        });
    }

    const _rowTable = (index, field, placeholder = '') => {

        if (mode) {
            return field ?
                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE
                            },
                            children: [
                                new Paragraph(index)
                            ],
                        }),
                        new TableCell({
                            width: {
                                size: 90,
                                type: WidthType.PERCENTAGE
                            },
                            children: [
                                new Paragraph(`${placeholder + ': ' + field}`)
                            ],
                        }),
                    ],
                }) : new TableRow({children: []});
        } else {
            let _temp = field ? field : ' - Дані відсутні - '.toUpperCase();

            let _index = new Paragraph(index);//_temp ? new Paragraph(index) : new Paragraph('');
            let _value = new Paragraph(`${placeholder + ': ' + _temp}`);//_temp ? new Paragraph(`${placeholder + ': ' + field}`) : new Paragraph('');

            return new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 10,
                            type: WidthType.PERCENTAGE
                        },
                        children: [
                            _index
                        ],
                    }),
                    new TableCell({
                        width: {
                            size: 90,
                            type: WidthType.PERCENTAGE
                        },
                        children: [
                            _value
                        ],
                    }),
                ],
            });
        }
    }

    function isEmpty(value) {
        return (value == null || (typeof value === "string" && value.trim().length === 0));
    }

    const imageToBlobData = async (files) => {

        return files.map((item, i) => {
            const _img = async () => await fetch(item.image).then(r => r.blob());
            return new ImageRun({
                data: _img(),
                transformation: {
                    width: 350,
                    height: 290,
                },
            });
        });
    }

    const generate = async () => {

        const blobImage = await fetch(file ?? _img).then(r => r.blob());

        const table3 = new Table({
            columnWidths: [350, 7550],
            width: {
                size: 100,
                type: WidthType.AUTO,
            },
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE
                            },
                            children: [new Paragraph('')],
                            verticalAlign: VerticalAlign.TOP,
                        }),
                        new TableCell({
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 90,
                                type: WidthType.PERCENTAGE
                            },
                            // margins: {
                            //     top: convertInchesToTwip(0.69),
                            //     bottom: convertInchesToTwip(0.69),
                            //     left: convertInchesToTwip(0.69),
                            //     right: convertInchesToTwip(0.69),
                            // },
                            children: [
                                new docx.Paragraph({
                                    children: [
                                        new ImageRun({
                                            data: blobImage,
                                            transformation: {
                                                width: 100,
                                                height: 120,
                                            },
                                        }),
                                        new docx.TextRun({
                                            text: surName + ' ' + firstName + ' ' + lastName,
                                            bold: true,
                                        }),
                                        new docx.TextRun({
                                            text: (dateBorn === "" || dateBorn == null) ? '' : ', ' + getParsedDate(dateBorn, '.') + ' р.н., ',
                                            bold: true,
                                        }),
                                        new docx.TextRun({
                                            text: job + ' ' + workPlace,
                                            bold: true,
                                        })
                                    ]
                                }),
                            ],
                        }),
                    ],
                }),

                _rowTable("", names, 'Можливі імена'),

                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE
                            },
                            children: [new Paragraph('1')],
                            verticalAlign: VerticalAlign.TOP,
                        }),
                        new TableCell({
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 90,
                                type: WidthType.PERCENTAGE
                            },
                            children: [

                                new docx.Paragraph({
                                    children: [
                                        new docx.TextRun({
                                            text: 'Контакти:',
                                            bold: true,
                                        }),
                                        // new docx.TextRun({
                                        //     text: field1 ?? '',
                                        // })
                                    ]
                                }),

                                (phones[0].text === '')
                                    ? new docx.Paragraph({children: [
                                            new docx.TextRun({
                                                text: ' - Дані відсутні - '.toUpperCase(),
                                            }),
                                        ]}) :
                                    createPhonesList(phones),

                                (socialMedia.length === 0 || socialMedia[0].url_or_id === '')
                                    ? new docx.Paragraph({children: [
                                            new docx.TextRun({
                                                text: ' - Соц. мережі: Незнайдено - '.toUpperCase(),
                                            }),
                                        ]}) :
                                    createSocialMediaLinksList(socialMedia),

                                new docx.Paragraph({
                                    children: [
                                        new docx.TextRun({
                                            text: 'Результаты взлома:',
                                            break: 1,
                                        }),
                                        new docx.TextRun({
                                            text: isEmpty(resultHack) ? '________________________' : resultHack,
                                        })
                                    ]
                                }),
                                new docx.Paragraph({
                                    children: [
                                        new docx.TextRun({
                                            text: 'Osintband:',
                                        }),
                                        new docx.TextRun({
                                            text: isEmpty(osintband) ? '________________________' : osintband,
                                        })
                                    ]
                                }),
                            ],
                        }),
                    ],
                }),

                _rowTable("2", field2, 'Email'),

                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE
                            },
                            children: [new Paragraph('3')],
                            verticalAlign: VerticalAlign.TOP,
                        }),
                        new TableCell({
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 90,
                                type: WidthType.PERCENTAGE
                            },
                            children: [

                                new docx.Paragraph({
                                    children: [
                                        new docx.TextRun({
                                            text: 'Пошукова система Google/Yandex:',
                                            bold: true,
                                        }),
                                    ]
                                }),

                                dataUrls.length > 0 ? createUrlsDataList(dataUrls) : new docx.Paragraph({
                                    children: [
                                        new docx.TextRun({
                                            text: ' - Дані відсутні - '.toUpperCase(),
                                        }),
                                    ]
                                }),
                            ],
                        }),
                    ],
                }),

                _rowTable("4", field4, 'SOFAROOMBOT'),
                _rowTable("5", field5, 'ORC_SEEKER'),
                _rowTable("6", field6, 'reveng.ee'),
                _rowTable("7", field7, 'Додаткова інформація'),

                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE
                            },
                            children: [new Paragraph('8')],
                            verticalAlign: VerticalAlign.TOP,
                        }),

                        new TableCell({
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 90,
                                type: WidthType.PERCENTAGE
                            },
                            children:

                                family.length > 0 ? createFamilyList(family) : [
                                    new docx.Paragraph({
                                        children: [
                                            new docx.TextRun({
                                                text: 'Родинні та інші зв\'язки:',
                                                bold: true,
                                            }),

                                        ]
                                    }),
                                    new docx.Paragraph({
                                        children: [
                                            new docx.TextRun({
                                                text: ' - Дані відсутні - '.toUpperCase(),
                                            }),
                                        ]
                                    })
                                ]

                        })
                    ]
                }),

                _rowTable("9", field9, "Нотатка"),


                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE
                            },
                            children: [new Paragraph('10')],
                            verticalAlign: VerticalAlign.TOP,
                        }),
                        new TableCell({
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 90,
                                type: WidthType.PERCENTAGE
                            },
                            children: [
                                new docx.Paragraph({
                                    children: [
                                        new docx.TextRun({
                                            text: 'Фотографії',
                                            bold: true,
                                        })
                                    ]
                                }),

                                new docx.Paragraph({
                                    children: images.length > 0 ? await imageToBlobData(images) : [
                                        new docx.TextRun({
                                            text: ' - Дані відсутні - '.toUpperCase(),
                                        })
                                    ]
                                }),

                            ],
                        }),
                    ],
                }),

            ],
        });


        const doc = new docx.Document({
            sections: [
                {
                    properties: {},
                    children: [
                        table3,
                    ]
                }
            ]
        });

        docx.Packer.toBlob(doc).then(async (blob) => {
            console.log(blob);
            saveAs(blob, `${_title}.docx`);

            console.log("Document created successfully");
        });

        if (modeSaveDownload) {
            const blob = await docx.Packer.toBlob(doc);
            var fileForSaveOnServer = new File([blob], `${_title}.docx`, {
                lastModified: (new Date()).getTime(),
                type: blob.type,
            });
            await saveFileOnServer(fileForSaveOnServer)
        }

        if (modeClear) {
            _clearFields();
        }

    }

    // Send File to server
    const [qr, setQr] = useState(null);

    const saveFileOnServer = async (file) => {

        try {
            const result = await uploadWebApi(file);

            if (result.success) {
                console.log(result.data)
                setQr(result.data?.qr)

                document.getElementById('qrModalShow').click()
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // Clear field's

    const _clearFields = () => {

        setFirstName('')
        setLastName('')
        setSurName('')
        setJob('')
        setWorkPlace('')
        setNames('')
        setDateBorn('')

        setOsintband("")
        setResultHack("")

        setFamily([{
            relationship: "",
            name: "",
            surname: "",
            lastname: "",
            photo: "",
            phone: "",
            isTelegram: false,
            isViber: false,
            isWhatsapp: false,
            isSignal: false,
            links: "",
            dataBorn: ""
        }])
        setDataUrls([{
            url: "",
            description: ""
        }])
        setSocialMedia([{
            source: "",
            url_or_id: "",
            username: ""
        }])
        setImages([{
            image: "",
            width: "",
            height: ""
        }])
        setPhones([
            {
                text: "",
                isTelegram: false,
                isViber: false,
                isWhatsApp: false,
                isSignal: false
            }
        ])

        setQr()

        setFile();
        // setField1("");
        setField2("");

        setField4("");
        setField5("");
        setField6("");
        setField7("");

        setField9("")

        // got to top profile
        window.scrollTo(0, 0)
    }

    const _icons = [
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             className="bi bi-cloud-download"
             viewBox="0 0 16 16">
            <path
                d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>
            <path
                d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share"
             viewBox="0 0 16 16">
            <path
                d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             className="bi bi-box-arrow-right"
             viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
            <path fill-rule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy"
             viewBox="0 0 16 16">
            <path d="M11 2H9v3h2z"/>
            <path
                d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
        </svg>
    ]

    return (

        <div id={'profile'}>

            <div className="nav-page-profile position-absolute d-none d-md-block">


                {
                    qr && <div className="text-center">
                        <QRCode
                            id="qrCodeProfile"
                            size={512}
                            style={{height: "150px", maxWidth: "150px", width: "100%", margin: "auto"}}
                            value={qr}
                            viewBox={`0 0 25 25`}
                        />
                        <br/>
                        <small className="text-secondary">{qr}</small>
                    </div>
                }


                <div className="d-flex flex-column text-start position-fixed">

                    {/*{modeSave && <div className={'pb-2'} style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>*/}
                    {/*    <QRCode*/}
                    {/*        size={512}*/}
                    {/*        style={{ height: "auto", maxWidth: "100%", width: "100%" }}*/}
                    {/*        value={'QR code'}*/}
                    {/*        viewBox={`0 0 512 512`}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    <h2>Зміст</h2>
                    <a href="#basicInformation"
                       className={'p-2 text-decoration-none shadow-sm bg-white text-secondary border-bottom'}>Основна
                        інформація</a>
                    <a href="#contacts"
                       className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Контакти</a>
                    {/*<a href="#address" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Адреси</a>*/}
                    <a href="#emails"
                       className={'p-2 text-decoration-none shadow-sm bg-white text-secondary border-bottom'}>Email's</a>
                    <a href="#link-g"
                       className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Google/Yandex</a>
                    <a href="#moreImformation"
                       className={'p-2 text-decoration-none shadow-sm bg-white text-secondary border-bottom'}>Додаткова
                        інформація</a>
                    <a href="#family" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Родині
                        зв'язки</a>
                    <a href="#note"
                       className={'p-2 text-decoration-none shadow-sm bg-white text-secondary border-bottom'}>Нотатка</a>
                    <a href="#photos"
                       className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Фотографії</a>
                    {/*<a href="#cars" className={'p-2 text-decoration-none shadow-sm bg-white text-secondary'}>Автомобілі</a>*/}

                    <div id="settings" className={'mt-3 p-1'}>
                        <small className="text-secondary mt-4">
                            <b>
                                Налаштування
                            </b>
                        </small>
                        <br/>
                        <div className="mt-2">
                            <b className={''}>Опція</b>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">

                            <div className={'align-items-center d-flex'}>
                                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"/>
                                </svg>
                                {/*<b>Mode </b>  */}
                                <small className="text-secondary p-1">
                                    {modeClear ? ' Вкл.' : ' Викл.'}
                                </small>
                            </div>

                            <input type="checkbox" id="toggleClear" className="checkbox"
                                   onChange={() => setModeClear(!modeClear)}/>

                            <label htmlFor="toggleClear" className="switch mt-1"></label>

                        </div>

                        <div className="d-flex justify-content-between align-items-start">

                            <div className={'align-items-center d-flex'}>
                                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"/>
                                </svg>
                                {/*<b>Mode </b>  */}
                                <small className="text-secondary p-1">
                                    {mode ? ' Вкл.' : ' Викл.'}
                                </small>
                            </div>

                            <input type="checkbox" id="toggleDownload" className="checkbox"
                                   onChange={() => setMode(!mode)}/>

                            <label htmlFor="toggleDownload" className="switch mt-1"></label>

                            {/*<button className={'rounded-0 w-100 btn ' +( mode ? 'btn-success' : 'btn-secondary')} onClick={() => setMode(!mode)}>Mode {mode ? 'On' : 'Off'}</button>*/}

                        </div>

                        {localStorage.getItem('uid') &&
                        <div className="d-flex justify-content-between align-items-start">

                            <div>
                                <div className={'align-items-center d-flex'}>
                                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                         viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"
                                              d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"/>
                                    </svg>
                                    <small className="text-secondary p-1">
                                        {modeSaveDownload ? ' Вкл.' : ' Викл.'}
                                    </small>
                                </div>
                            </div>

                            <input type="checkbox" id="toggleSaveDownload" className="checkbox"
                                   onChange={() => setModeSaveDownload(!modeSaveDownload)}/>

                            <label htmlFor="toggleSaveDownload" className="switch mt-1"></label>

                            {/*<button className={'rounded-0 w-100 btn ' +( mode ? 'btn-success' : 'btn-secondary')} onClick={() => setMode(!mode)}>Mode {mode ? 'On' : 'Off'}</button>*/}

                        </div>
                        }

                        <a href="#information-section"
                           className={'text-decoration-none text-secondary align-items-center d-flex mt-2'}>
                            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <small className="text-secondary">Детальніше</small>
                        </a>

                    </div>
                </div>

            </div>

            <div className="form d-flex flex-wrap col-md-8 m-auto mt-5 mb-5">

                <div id={'basicInformation'} className="col-md-4 col-12 p-1 wrap-image text-start">
                    <div className="col-md-12 col-12 text-start p-2">
                        <h4 className="text-secondary text-uppercase">
                            Фотографія
                        </h4>
                    </div>
                    <img src={file ?? _img} alt="avatar"
                         className={'btn-img img-fluid border shadow col-md-10 col-12'}/>
                    <input type='file' id='file' onChange={handleChangeFile}/>
                    {/*<small className="text-secondary d-none d-md-block">*/}
                    {/*    Фотографія*/}
                    {/*</small>*/}
                </div>

                <div className="d-flex flex-wrap col-md-8 col-12 p-1">
                    <div className="col-md-12 col-12 text-start p-2">
                        <h4 className="text-secondary text-uppercase">
                            інформація
                        </h4>
                    </div>

                    <div className="bg-white shadow p-2 col-md-12 d-flex flex-wrap">
                        <div className="col-md-6 col-12 p-2">
                            <InputComponent
                                type={'text'}
                                name={'sur_name'}
                                value={surName}
                                onChange={(e) => setSurName(e.target.value)}
                                placeholder={'Прізвище'}
                                class={'form-control rounded-0 border-0 border-bottom'}
                            />
                        </div>
                        <div className="col-md-6 col-12 p-2">
                            <InputComponent
                                type={'text'}
                                name={'first_name'}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder={'Імя'}
                                class={'form-control rounded-0 border-0 border-bottom'}
                            />
                        </div>
                        <div className="col-md-6 col-12 p-2">
                            <InputComponent
                                type={'text'}
                                name={'last_name'}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder={'По-батькові'}
                                class={'form-control rounded-0 border-0 border-bottom'}
                            />
                        </div>
                        <div className="col-md-12 col-12 d-flex flex-wrap align-items-center">
                            <div className="col-md-6 col-12 p-2 text-end">
                                <small className="text-secondary">
                                    Дата народження:
                                </small>
                            </div>
                            <div className="col-md-6 col-12 p-2 text-start">
                                <InputComponent
                                    type={'date'}
                                    name={'date_born'}
                                    value={dateBorn}
                                    onChange={(e) => setDateBorn(e.target.value)}
                                    placeholder={'Дата народження'}
                                    class={'form-control rounded-0 border-0 border-bottom'}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 p-2">
                            <InputComponent
                                type={'text'}
                                name={'job'}
                                value={job}
                                onChange={(e) => setJob(e.target.value)}
                                placeholder={'Посада'}
                                class={'form-control rounded-0 border-0 border-bottom'}
                            />
                        </div>
                        <div className="col-md-6 col-12 p-2">
                            <InputComponent
                                type={'text'}
                                name={'work_place'}
                                value={workPlace}
                                onChange={(e) => setWorkPlace(e.target.value)}
                                placeholder={'Місце роботи'}
                                class={'form-control rounded-0 border-0 border-bottom'}
                            />
                        </div>

                    </div>
                </div>


                <div className="col-md-12 col-12 mt-3 p-1 mb-5">
                    <TextAreaField
                        index={''}
                        id={'names'}
                        name={'names'}
                        title={"Можливі імена".toUpperCase()}
                        value={names}
                        onChange={(e) => setNames(e.target.value)}
                        placeholder={"Псевдоніми"}
                        class={"form-control mb-2 rounded-0 shadow"}
                        typeSize={'full'}
                    />
                </div>

                <div className="col-md-1">
                    <small className="text-secondary">
                        1
                    </small>
                </div>
                <div id={'contacts'} className="col-md-11 text-start mb-2">
                    <h4 className="text-secondary text-uppercase">
                        Контакти
                    </h4>
                    <div className="border p-3 bg-white shadow">


                        <div id="phones">
                            {
                                phones.map((phone, i) => <div key={i}>

                                        <PhoneField
                                            id={i}
                                            value={phone.text}
                                            onChange={(e) => handleChangePhonesValue(i, e)}
                                            add={handleInsertPhonesValue}
                                            remove={() => handleRemovePhonesValue(i)}
                                            messengerOptionChange={messengerOptionChange}
                                            telegram={phone.isTelegram}
                                            viber={phone.isViber}
                                            whatsapp={phone.isWhatsApp}
                                            signal={phone.isSignal}
                                        />

                                    </div>
                                )
                            }
                        </div>

                        <br/>
                        <small className="text-secondary">
                            Соціальні мережі
                        </small>
                        <div id="social-media">
                            {
                                socialMedia.map((link, i) => <div key={i}
                                                                  className="d-flex justify-content-around align-items-center">

                                    <select className={'form-control rounded-0 border-0 border-bottom m-1'}
                                            onChange={(e) => handleChangeSocialMediaValue(i, e, 'source')}>
                                        <option value="#" selected disabled>- джерело -</option>
                                        {
                                            _sourcesSocialMedia.map((source, index) =>
                                                <option key={index} value={source.value}
                                                        selected={source.value === link.source}>{source.label}</option>
                                            )
                                        }
                                    </select>
                                    <InputComponent
                                        type={'text'}
                                        name={'messenger_url_or_id'}
                                        value={link.url_or_id}
                                        onChange={(e) => handleChangeSocialMediaValue(i, e, 'url_or_id')}
                                        placeholder={'URL/ID'}
                                        class={'form-control rounded-0 border-0 border-bottom m-1'}
                                    />
                                    <InputComponent
                                        type={'text'}
                                        name={'messenger_username'}
                                        value={link.username}
                                        onChange={(e) => handleChangeSocialMediaValue(i, e, 'username')}
                                        placeholder={'@username'}
                                        class={'form-control rounded-0 border-0 border-bottom m-1'}
                                    />

                                    {i !== 0 && <button onClick={() => handleRemoveSocialMediaValue(i)}
                                                        className={'btn btn-outline-danger rounded-0'}>-</button>}
                                    {i === 0 && <button onClick={handleInsertSocialMediaValue}
                                                        className={'btn btn-secondary rounded-0'}>+</button>}
                                </div>)
                            }
                        </div>

                    </div>

                    {/*<div id={'address'} className="p-2 mt-5">*/}
                    {/*    <div className="d-flex justify-content-between align-items-center">*/}
                    {/*        <h4 className="text-secondary text-uppercase">*/}
                    {/*            Адреси*/}
                    {/*        </h4>*/}
                    {/*        <button onClick={() => {}} className={'btn btn-secondary rounded-0'}>+</button>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex flex-wrap">*/}
                    {/*        <AddressField />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-secondary">
                            Результаты взлома:
                        </small>
                        <InputComponent
                            type={'text'}
                            name={'result_hack'}
                            placeholder={''}
                            value={resultHack}
                            onChange={(e) => setResultHack(e.target.value)}
                            class={'form-control w-75 rounded-0 border-0 border-bottom'}
                        />
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <small className="text-secondary">
                            Osintband
                        </small>
                        <InputComponent
                            type={'text'}
                            name={'osintband'}
                            value={osintband}
                            onChange={(e) => setOsintband(e.target.value)}
                            placeholder={''}
                            class={'form-control w-75 rounded-0 border-0 border-bottom'}
                        />
                    </div>

                    {/*<textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"Контакти, соц. мережі"}*/}
                    {/*          onChange={(e) => setField1(e.target.value)}*/}
                    {/*>*/}
                    {/*    {field1}*/}
                    {/*</textarea>*/}
                </div>

                <TextAreaField
                    index={'2'}
                    id={'emails'}
                    name={'emails'}
                    title={"Email's"}
                    value={field2}
                    onChange={(e) => setField2(e.target.value)}
                    placeholder={"name@domain.com"}
                    class={"form-control mb-2 rounded-0 shadow"}
                />

                {/*<TextAreaField*/}
                {/*    index={'3'}*/}
                {/*    id={'Google/Yandex'}*/}
                {/*    name={'Google/Yandex'}*/}
                {/*    title={"Пошукова система Google/Yandex"}*/}
                {/*    value={field3}*/}
                {/*    onChange={(e) => setField3(e.target.value)}*/}
                {/*    placeholder={"Google/Yandex"}*/}
                {/*    class={"form-control mb-2 rounded-0 shadow"}*/}
                {/*/>*/}


                <div id={'link-g'} className={'w-100 d-flex mt-3'}>

                    <div className="col-md-1">
                        <small className="text-secondary">
                            3
                        </small>
                    </div>


                    <div className={props.typeSize === 'full' ? "col-md-12 text-start" : "col-md-11 text-start"}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="text-secondary text-uppercase">
                                Пошукова система Google/Yandex
                            </h4>
                            <button onClick={setUrlToData} type={'button'}
                                    className={'btn btn-secondary rounded-0 m-1'}>+
                            </button>
                        </div>
                        <div className={(dataUrls.length > 0 && "bg-white shadow-lg") + " pb-2 pt-3"}>
                            {
                                dataUrls.map((url, i) => <div key={i}
                                                              className="d-flex align-items-center justify-content-between">
                                    <InputComponent
                                        type={'text'}
                                        name={'url_value[]'}
                                        value={url.url}
                                        onChange={(e) => changeUrlInData(i, e, 'url')}
                                        placeholder={'URL/Посилання'}
                                        class={'form-control m-1 rounded-0 border-0 border-bottom'}
                                    />
                                    <InputComponent
                                        type={'text'}
                                        name={'url_description[]'}
                                        value={url.description}
                                        onChange={(e) => changeUrlInData(i, e, 'description')}
                                        placeholder={'Опис'}
                                        class={'form-control m-1 rounded-0 border-0 border-bottom'}
                                    />
                                    {/*{ i === 0 && <button onClick={setUrlToData}  type={'button'} className={'btn btn-secondary rounded-0 m-1'}>+</button>}*/}
                                    {/*{ i !== 0 && <button onClick={() => removeUrlFromData(i)} type={'button'} className={'btn btn-danger rounded-0 m-1'}>-</button>}*/}
                                    <button onClick={() => removeUrlFromData(i)} type={'button'}
                                            className={'btn btn-danger rounded-0 m-1'}>-
                                    </button>
                                </div>)
                            }

                        </div>
                    </div>
                </div>

                <TextAreaField
                    index={'4'}
                    id={'SOFAROOMBOT'}
                    name={'SOFAROOMBOT'}
                    title={"SOFAROOMBOT"}
                    value={field4}
                    onChange={(e) => setField4(e.target.value)}
                    placeholder={"SOFAROOMBOT"}
                    class={"form-control mb-2 rounded-0 shadow"}
                />

                <TextAreaField
                    index={'5'}
                    id={'ORC_SEEKER'}
                    name={'ORC_SEEKER'}
                    title={"ORC_SEEKER"}
                    value={field5}
                    onChange={(e) => setField5(e.target.value)}
                    placeholder={"ORC_SEEKER"}
                    class={"form-control mb-2 rounded-0 shadow"}
                />

                <TextAreaField
                    index={'6'}
                    id={'reveng.ee'}
                    name={'reveng.ee'}
                    title={"reveng.ee"}
                    value={field6}
                    onChange={(e) => setField6(e.target.value)}
                    placeholder={"reveng.ee"}
                    class={"form-control mb-2 rounded-0 shadow"}
                />

                <TextAreaField
                    index={'7'}
                    id={'moreImformation'}
                    name={'Додаткова інформація'}
                    title={"Додаткова інформація"}
                    value={field7}
                    onChange={(e) => setField7(e.target.value)}
                    placeholder={"Додаткова інформація"}
                    class={"form-control mb-2 rounded-0 shadow"}
                />

                <div id={'family'} className="col-md-12 d-flex mt-4">
                    <div className="col-md-1">
                        <small className="text-secondary">
                            8
                        </small>
                    </div>
                    <div className="col-md-11 text-start">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h4 className="text-secondary text-uppercase">
                                Родинні та інші зв’язки
                            </h4>

                            <button onClick={handleInsertFamilyValue} className={'btn btn-secondary rounded-0'}>+
                            </button>
                        </div>
                        {/*<textarea name="" id="" cols="30" rows="2" className="form-control mb-2" placeholder={"Родинні та інші зв’язки"}*/}
                        {/*          onChange={(e) => setField8(e.target.value)}*/}
                        {/*>*/}
                        {/*    {field8}*/}
                        {/*</textarea>*/}

                        <div className="bg-white shadow">

                            {
                                family.map((member, i) =>
                                    <FamilyField
                                        id={i}
                                        value={member}
                                        change={handleChangeFamilyValue}
                                        remove={() => handleRemoveFamilyValue(i)}
                                        handleChangeFilePhotoFamily={(e) => handleChangeFilePhotoFamily(i, e)}
                                        handleChangeFamilyMessengerValue={handleChangeFamilyMessengerValue}
                                    />
                                )
                            }
                        </div>

                    </div>
                </div>

                <TextAreaField
                    index={'9'}
                    id={'note'}
                    name={'Нотатка'}
                    title={"Нотатка"}
                    value={field9}
                    onChange={(e) => setField9(e.target.value)}
                    placeholder={"Нотатка"}
                    class={"form-control mb-2 rounded-0 shadow"}
                />

                <div id={'photos'} className="d-flex col-md-12 mt-5 mb-5">
                    <div className="col-md-1">
                        <small className="text-secondary">
                            10
                        </small>
                    </div>
                    <div className="col-md-11 text-start">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="text-secondary text-uppercase">
                                Фотографії
                            </h4>
                            <button onClick={addImage} className={'btn btn-secondary rounded-0'}>+</button>
                        </div>
                        <div className="d-flex flex-wrap">

                            {
                                images.map((item, i) => <div key={i} className="col-md-3 p-1">
                                    <img src={isEmpty(item.image) ? _img : item.image} alt="avatar"
                                         className={'btn-img img-fluid shadow border'}/>
                                    <input type='file' id={'filePhoto' + i}
                                           onChange={(e) => changeImage(i, e)}
                                           className={'d-none'} accept="image/*"/>
                                    <button onClick={() => {
                                        document.getElementById('filePhoto' + i)?.click()
                                    }} className={'btn btn-secondary mt-2 rounded-0 w-100'}>
                                        Обрати фотографію
                                    </button>

                                    <button onClick={() => removeImage(i)}
                                            className={'btn btn-outline-danger rounded-0 mt-1 w-100 text-uppercase'}>Видалить
                                    </button>
                                </div>)
                            }

                        </div>
                    </div>
                </div>

                {/*<div id={'cars'} className="d-flex col-md-12 mt-5 mb-5">*/}
                {/*    <div className="col-md-1">*/}
                {/*        <small className="text-secondary">*/}
                {/*            11*/}
                {/*        </small>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-11 text-start">*/}
                {/*        <div className="d-flex justify-content-between align-items-center">*/}
                {/*            <h4 className="text-secondary text-uppercase">*/}
                {/*                Автомобілі*/}
                {/*            </h4>*/}
                {/*            <button onClick={() => {}} className={'btn btn-secondary rounded-0'}>+</button>*/}
                {/*        </div>*/}
                {/*        <div className="d-flex flex-wrap">*/}
                {/*            <CarField />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="col-md-12">
                    <button className={'btn btn-dark w-100 p-3 mt-2 rounded-0 pt-4 pb-4 text-uppercase'}
                            onClick={generate}>
                        {_icons[0]} Завантажить
                    </button>

                    <div className="pt-5">
                        <button onClick={_clearFields}
                                className={'btn text-decoration-none text-secondary text-uppercase'}>Очиистити профайл
                        </button>
                    </div>
                </div>


            </div>

            <div className="col-md-12 col-12 fixed-bottom p-2 text-end mb-5">
                <button className={'btn btn-dark col-md-2 p-3 mt-2 rounded-0 pt-4 pb-4 text-uppercase shadow-sm'}
                        onClick={generate}>
                    {_icons[0]} Завантажить
                </button>
                {
                    qr && <WindowModal
                        text={'Профайл збережено!'}
                        btnText={'QR код'}
                        qrCodeText={qr}
                    />
                }
            </div>

        </div>
    );
}

export default Profile2;
